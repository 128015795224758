import React from 'react';
import DocTitle from 'react-document-title';
import ajax from 'arcdynamic-request';
import currency from '../../currency';
import ReactiveWrapper from '../ReactiveWrapper';
import Error404 from '../Error404';
import Photo from '../Photo';
import Link from '../Link';
import CartReminder from '../CartReminder';

export default ReactiveWrapper(React.createClass({
	getInitialState() {
		return {
			isFetching: true,
			product: false,
			quantity: 1,
			isSubmitting: false,
		};
	},
	fetch(sku) {
		this.setState({isFetching: true});

		ajax(arc.path.store, {
			service: 'cart',
			action: 'store.Product.get',
			options: {
				filter: {
					sku,
				},
				flag: {
					file: true,
					attributeValue: true,
					tag: true,
				},
				limit: {
					count: 1,
				},
			}
		}).then(res => {
			this.setState({
				product: res.data && res.data[0],
				isFetching: false,
			})
		});
	},
	handleQuantity(e) {
		const quantity = e.target.value;
		if (quantity === '' || (Number(quantity) === Number(quantity) && Number(quantity) > 0)) {
			this.setState({quantity});
		}
	},
	handleSubmit(e) {
		e.preventDefault();
		
		const quantity = Math.max(Number(this.state.quantity) || 1, 1);
		const id = this.state.product.id;

		this.setState({isSubmitting: true});

		const addProduct = ({ orderIndex }) => {
			ajax(arc.path.store, {
				service: 'cart',
				action: 'store.Cart.addProduct',
				params: [orderIndex, id, quantity],
			}).then((res)=>{
				if (res.success) {
					this.props.setCart(res.data, ()=> {
						this.refs.reminder.open();
					});
				}

				this.setState({
					isSubmitting: false,
				});
			});
		}

		// handle named order
		if (this.state.product.tags.some(x => x.code === 'ShipSolo')) {
			ajax(arc.path.store, {
				service: 'cart',
				action: 'store.Cart.createOrder',
				params: [null, this.state.product.name],
			})
			.then(res => {
				if (res.success && res.data && res.data.orderIndex) {
					addProduct({ orderIndex: res.data.orderIndex });
				} else {
					this.setState({
						// todo: show error
						isSubmitting: false,
					});
				}
			}).catch(exception)
		}
		// handle unnamed order
		else {
			if (!this.props.cart) {
				// an unnamed order will be created automatically if no cart
				addProduct({ orderIndex: null })
			} else {
				const unnamedOrder = Object.values(this.props.cart.orders).find(x => x.name === null);

				// put product into first available unnamed order
				if (unnamedOrder) {
					addProduct({ orderIndex: unnamedOrder.orderIndex })
				} else {
					ajax(arc.path.store, {
						service: 'cart',
						action: 'store.Cart.createOrder',
						params: [null, null],
					})
					.then(res => {
						if (res.success && res.data && res.data.orderIndex) {
							addProduct({ orderIndex: res.data.orderIndex });
						} else {
							this.setState({
								// todo: show error
								isSubmitting: false,
							});
						}
					}).catch(exception)
				}

			}
		}
	},
	componentDidMount() {
		this.fetch(this.props.params.sku);
	},
	componentWillReceiveProps(nextProps) {
		if (nextProps.params.sku !== this.props.params.sku) {
			this.fetch(nextProps.params.sku);
		}
	},
	render() {
		if (this.state.isFetching) return null;
		const product = this.state.product;

		if (!product) return <Error404/>;

		const thumb = Array.isArray(product.files) && product.files.filter(x => x.type === 'image').shift();

		return (
			<div className='SupplyProduct'>
				<DocTitle title='Davids Cookies - Supplies'/>
				<div className='container ProductDetail_breadcrumbs'>
					<Link className='capitalize' to='/supplies/'>Supplies</Link>
				</div>
				<div className='container'>
					<div className='container_side'>
						<Photo className='w-full' src={thumb ? thumb.url : null} alt={product.name} width='300'/>
					</div>
					<div className='container_main'>
						<h1>{product.name}</h1>
						{
							product.attributeValues && product.attributeValues.length ? (
								<div className='subheading'>
									{product.attributeValues.map(el => el.name).join(' | ')}
								</div>
							) : null
						}
						<div className='SupplyProduct_desc' dangerouslySetInnerHTML={{__html: product.desc}}/>
						<div className='SupplyProduct_price'>{currency(product.price)}</div>
						<form className='SupplyProduct_add' onSubmit={this.handleSubmit}>
							<div className='SupplyProduct_add_body'>
								<input type='number' onChange={this.handleQuantity} value={this.state.quantity} min={1} max={product.limitPerOrder && Number(product.limitPerOrder) || null}/>
								<button data-is-busy={this.state.isSubmitting || null}>Add To Cart</button>
							</div>
							{
								(product.limitPerOrder && Number(product.limitPerOrder)) ? (
									<div className='SupplyProduct_add_limit'>
										Limit {product.limitPerOrder} per order
									</div>
								) : null
							}
						</form>
					</div>
				</div>
				<CartReminder ref='reminder' cart={this.props.cart}/>
			</div>
		);
	},
}));
