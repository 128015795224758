import React from 'react';
import contents from '../../contents';

export default React.createClass({
	getInitialState() {
		return {
			tree: null,
			active: null,
		};
	},
	refresh() {
		this.destroyContents();

		const articles = document.body.querySelectorAll('h1,h2');

		if (articles.length) {
			this.contents = contents({
				articles,
			});
			this.setState({tree: this.contents.tree()});
			this.changeListener = this.contents.eventEmitter().on('change', (data)=> {
				this.setState({active: data.current.article})
			});

			{
				// Refresh offset positions when an image loads
				const imgs = document.body.querySelectorAll('img');
				[].slice.call(imgs, this).forEach(el => {
					function fn(e) {
						el.removeEventListener('load', fn);
						clearTimeout(this.timeout);
						this.timeout = setTimeout(()=>{
							if (this.contents) {
								this.contents.eventEmitter().trigger('resize');
							}
						}, 100);
					}

					el.addEventListener('load', fn);
				});
			}
		}
	},
	destroyContents() {
		if (this.contents) {
			this.contents.eventEmitter().off(this.changeListener);
			this.contents = null;
			this.setState({
				tree: null,
				active: null,
			})
		}
	},
	componentDidMount() {
		this.refresh();
	},
	componentWillUnmount() {
		this.destroyContents();
	},
	renderList(list) {
		return (
			<ol className={'Contents_level'+list[0].level}>
				{
					list.map((el,i) => {
						return (
							<li key={el.id}>
								<a className={this.props.hash.slice(1) === el.id ? 'active' : ''} href={'#'+el.id}>{el.name}</a>
								{el.descendants.length ? this.renderList(el.descendants) : null}
							</li>
						)
					})
				}
			</ol>
		);
	},
	render() {
		if (!this.state.tree) return null;

		return (
			<div className='Contents sticky'>
				{this.renderList(this.state.tree)}
			</div>
		);
	},
});
