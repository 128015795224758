import React from 'react';
import ajax from 'arcdynamic-request';

export default React.createClass({
	getInitialState() {
		return {
			inputValue: '',
			product: null,
			isSubmitting: false,
			errorMessage: null,
		};
	},
	handleSubmit(e) {
		e.preventDefault();

		if (this.state.isSubmitting) return;

		const ITEMID = this.state.inputValue;

		this.setState({
			inputValue: '',
			isSubmitting: true,
			product: null,
			errorMessage: null,
		})

		ajax(arc.path.api, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.getData',
			params: ['code', 'DAVIDSDATAPRODUCTS', {
				filter: [
					{
						text: ITEMID,
						code: 'ITEMID',
						type: 'exact',
					},
				],
				limit: {
					count: 1,
				},
			}],
		}, {cache: true}).then((res)=>{
			let product = res && res.data && res.data.length > 0 ? res.data[0] : false;

			if (product && product.LABEL_FILE_URL) {
				const a = document.createElement('a');
				a.href = res.data[0].LABEL_FILE_URL;
				a.target = '_blank';
				a.click();

				ajax(arc.path.api, {
					service: 'arcimedes',
					action: 'open.datasource.table.Data.updateByTableCode',
					params: ['CREATE_LABEL_CLICKS', {
						value: [
							{
								ITEM: ITEMID,
							},
						],
					}],
				}).then(res => {
					console.log('done',res);
				}).catch(e => {
					console.error(e);
				})
			}

			this.setState({
				isSubmitting: false,
				product,
				errorMessage: res && res.errorCode && res.message || null,
			})
		});
	},
	handleInput(e) {
		this.setState({inputValue: e.currentTarget.value})
	},
	render() {
		let message = null;

		if (this.state.errorMessage) {
			message = <div style={{color: 'red'}}>{this.state.errorMessage}</div>
		}
		else if (this.state.product) {
			if (this.state.product.LABEL_FILE_URL) {
				message = <a href={this.state.product.LABEL_FILE_URL} style={{textDecoration: 'underline'}} target='_blank' download={true}>Download PDF for #{this.state.product.ITEMID} ({this.state.product.PRODUCT_NAME})</a>
			} else {
				message = <div style={{color: 'red'}}>Sorry, no label found for that product.</div>
			}
		} else if (this.state.product === false) {
			message = <div style={{color: 'red'}}>Product not found</div>
		}

		return (
			<div className='Merchandising_main'>
				<form className='Merchandising_form' onSubmit={this.handleSubmit}>
					<input type='text' value={this.state.inputValue} onInput={this.handleInput}/>
					<button data-is-busy={this.state.isSubmitting || null}>Create Label</button>
				</form>
				{message && <div className='Merchandising_message'>{message}</div>}
			</div>
		);
	}
});
