import React from 'react';
import DocTitle from 'react-document-title';

export default React.createClass({
	render() {
		return (
			<div>
				<DocTitle title='Page Not Found'/>
				<div className='small-container'>
					<h1>Page Not Found</h1>
					<p>The page you are looking for does not exist, or the URL has changed.</p>
				</div>
			</div>
		);
	},
});