import React from 'react';
import Slider from '../Slider/';
import DocTitle from 'react-document-title';
import ajax from 'arcdynamic-request';
import Editable from '../Editable';
import Contents from '../Contents';
import ReactiveWrapper from '../ReactiveWrapper';

export default ReactiveWrapper(React.createClass({
	statics: {
		fetch(props) {
			return Promise.all([
				ajax(arc.path.api, {
					service: 'arcimedes',
					action: 'open.datasource.table.Data.getData',
					params: ['code', 'DAVIDSPAGES', {
						filter: [
							{
								text: 'home',
								code: 'PAGECODE',
								type: 'exact',
							},
						],
						limit: {
							count: 1,
						},
					}],
				}, {cache: true}).then((res)=>{
					return {
						page: res && res.success ? res.data[0] : false,
					}
				}),
				
				ajax(arc.path.api, {
					service: 'arcimedes',
					action: 'open.datasource.table.Data.getData',
					params: ['code', 'FOODSERVICESHOMESLIDES2'], 
				}).then((res)=>{
					return {
						slides: res && res.success ? res.data : [],
					}
				}),
			]).catch(err => {
				throw err;
			});
		}
	},
	onSave(column, content) {
		return ajax(arc.path.api, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.updateByTableCode',
			params: ['DAVIDSPAGES', {
				value: [
					{ARCIMEDES_ROW_ID: this.props.page.ARCIMEDES_ROW_ID, [column]: content},
				],
			}],
		}, {cache: true}).then((res)=>{
			// todo
		});
	},
	render() {
		return (
			<div className='Home'>
				<DocTitle title='Davids Cookies - Food Service'/>
				<Slider slides={this.props.slides}/>
				<div className='container'>
					<div className='container_side no-print'>
						<Editable html={this.props.page.ASIDE} enabled={this.props.isAdmin} onSave={(html)=> this.onSave('ASIDE',html)}/>
						<Contents ref='contents' hash={this.props.location.hash}/>
					</div>
					<div className='container_main'>
						<Editable tag='h1' html={this.props.page.TITLE} enabled={this.props.isAdmin} whitelist={{}} onSave={(html, text)=> this.onSave('TITLE',text)}/>
						<Editable html={this.props.page.MAIN} enabled={this.props.isAdmin} onSave={(html)=> this.onSave('MAIN',html)}/>
					</div>
				</div>
			</div>
		);
	},
}));
