import React from 'react';
import currency from '../../currency';

export default React.createClass({
	render() {
		if (!this.props.cart) {
			return null;
		}

		const orders = this.props.cart.orders ? Object.keys(this.props.cart.orders).map(key => this.props.cart.orders[key]).filter(el => el.products.length) : []

		if (!orders.length) {
			return null;
		}

		return (
			<div className='CartFlyout'>
				{
					orders.map(el => {
						const count = el.products.reduce((a, b) => { return a+b.quantity}, 0);

						return (
							<div key={el.orderIndex} className='CartFlyout_item'>
								<div className='CartFlyout_item_label'>
									{count.toLocaleString()} {count !== 1 ? 'items' : 'item'} going to {el.name ? el.name : 'You'}
								</div>
								<div className='CartFlyout_item_price'>
									{currency(el.total)}
								</div>
							</div>
						);
					})
				}
				<div className='CartFlyout_total'>
					<div className='CartFlyout_item'>
						<div className='CartFlyout_item_label'>
							Total
						</div>
						<div className='CartFlyout_item_price CartFlyout_item_price--total'>
							{currency(this.props.cart.total)}
						</div>
					</div>
				</div>
				<div className='CartFlyout_nav'>
					<a className='CartFlyout_btn CartFlyout_btn--cart' href={arc.path.cart}>View Cart</a> <a className='CartFlyout_btn CartFlyout_btn--checkout' href={arc.path.cart+'checkout'}>Checkout</a>
				</div>
			</div>
		);
	},
});