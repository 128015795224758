import React from 'react';
import Link from '../Link';
import Image from 'arc-dynamic-img';
import imgSrc from 'arcdynamic-resize';

export default React.createClass({
	render() {
		const product = this.props.product;

		if (!product.ITEMID) { return null; }

		let src = product.IMAGE_FILE_NAME && product.IMAGE_FILE_NAME !== '0' ? product.IMAGE_FILE_NAME : null;
		if (src && product.IMAGE_FILE_NAME.indexOf('http') !== 0) { // todo: eventually remove
			src = arc.path.arcMedia+'img/products/'+product.IMAGE_FILE_NAME+'.jpg';
		}

		return (
			<Link className='MiniProduct' to={arc.path.base+'product/'+product.ITEMID}>
				<Image className='MiniProduct_img' src={imgSrc(src,300)} width={300}/>
				<div className='MiniProduct_body'>
					<div className='MiniProduct_name'>{product.PRODUCT_NAME.toLowerCase()}</div>
					{
						product.CATEGORY ? (
							<div>
								<small className='MiniProduct_category'>{product.SUBCATEGORY ? product.SUBCATEGORY.toLowerCase() : product.CATEGORY.toLowerCase()}</small>
							</div>
						) : null
					}
					{product.DESCRIPTION ? <div>{product.DESCRIPTION}</div> : null }
				</div>
			</Link>
		);
	},
});
