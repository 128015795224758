import React from 'react';
import {Link} from 'react-router';

export default React.createClass({
	render() {
		return (
			<Link {...this.props} onClick={()=> window.scrollTo(0, 0)}/>
		);
	},
});
