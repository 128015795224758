import './css/main.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import tree from './tree';
import es6Promise from 'es6-promise';

es6Promise.polyfill();

tree.set(window.app.data);

window.app.state = tree;

let fn;

tree.off('update', fn);
fn = (()=> ReactDOM.render(React.cloneElement(Routes), document.getElementById('react-root')));
tree.on('update', fn);
tree.commit();