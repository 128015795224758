import React from 'react';
import ReactiveWrapper from '../ReactiveWrapper';
import DocTitle from 'react-document-title';
import ajax from 'arcdynamic-request';
import Image from 'arc-dynamic-img';
import Link from '../Link';
import imgSrc from 'arcdynamic-resize';
import legacyMedia from '../../legacy-media';

export default ReactiveWrapper(React.createClass({
	statics: {
		fetch() {
			return Promise.all([
				ajax(arc.path.api, {
					service: 'arcimedes',
					action: 'open.datasource.table.Data.getData',
					params: ['code', 'DAVIDSDATACATEGORIES', {
						column: [
							'CATEGORY',
							'IMAGE',
							'TITLE',
							'POSITION',
						],
						filter: [
							{
								text: '',
								code: 'SUBCATEGORY',
								type: 'null',
							}
						],
						limit: {
							count: 1000,
						},
					}],
				}, {cache: true}).then((res)=>{
					// sort categories by POSITION only if they have a POSITION value
					return {
						rootCategories: res && res.success ? res.data.sort((a,b)=> {
							if (a.POSITION) {
								if (b.POSITION) {
									return Number(a.POSITION) < Number(b.POSITION) ? -1 : 1;
								} else {
									return -1;
								}
							} else if (b.POSITION) {
								return 1;
							}
							return 0;
						}) : [],
					}
				})
			]);
		}
	},
	render() {
		return (
			<div className='Categories'>
				<DocTitle title='Product Categories'/>
				<h2>Product Categories</h2>
				<ul className='Categories_list'>
				{
					this.props.rootCategories.map((el,i) => (
						<li key={i}>
							<Link to={arc.path.base+'products/'+el.CATEGORY}>
								<Image src={el.IMAGE ? imgSrc(legacyMedia(el.IMAGE), 300) : null}/>
								<div className='Categories_name'>{el.TITLE}</div>
							</Link>
						</li>
					))
				}
				</ul>
			</div>
		);
	},
}));
