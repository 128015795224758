import React from 'react';
import ReactiveWrapper from '../ReactiveWrapper';
import CategoryFilter from '../CategoryFilter';
import ajax from 'arcdynamic-request';
import Products from '../Products';
import Link from '../Link';

export default ReactiveWrapper(React.createClass({
	statics: {
		fetch() {
			return Promise.all([
				ajax(arc.path.api, {
					service: 'arcimedes',
					action: 'open.datasource.table.Data.getData',
					params: ['code', 'DAVIDSDATACATEGORIES', {
						column: [
							'CATEGORY',
							'SUBCATEGORY',
							'TITLE',
							'POSITION',
						],
						limit: {
							count: 1000,
						},
					}],
				}, {cache: true}).then((res)=>{
					return {
						categories: res && res.success ? res.data.sort((a,b)=> {
							if (a.POSITION) {
								if (b.POSITION) {
									return Number(a.POSITION) < Number(b.POSITION) ? -1 : 1;
								} else {
									return -1;
								}
							} else if (b.POSITION) {
								return 1;
							}
							return 0;
						}) : [],
					}
				})
			]);
		}
	},
	render() {
		return (
			<div className='ProductsRoot'>
				<div className='container'>
					<div className='container_side container_side-no-print'>
						<div className='ProductsRoot_filters'>
							<h3>Browse by Category</h3>
							<CategoryFilter categories={this.props.categories} activeCategory={this.props.params.categoryCode} pathname={this.props.location.pathname}/>
						</div>
						<br/>							
						<div className='ProductsRoot_baking'>
							<h3>Baking & Handling</h3>
							<div className='ProductsRoot_li'>
								<div className='ProductRoot_li_1'>
									<Link className='ProductsRoot_li_1' to={arc.path.base+'baking-handling/#cookie-dough/'} target='_blank'>Cookie Dough
									</Link>
								</div>
								<div className='ProductRoot_li_3'>
									<Link className='ProductsRoot_li_3' to={arc.path.base+'baking-handling/#muffin-batter/'} target='_blank'>Muffin Batter
									</Link>
								</div>								
								<div className='ProductRoot_li_4'>
									<Link className='ProductsRoot_li_4' to={arc.path.base+'baking-handling/#scone-dough/'} target='_blank'>Scone Dough
									</Link>
								</div>
								<div className='ProductRoot_li_2'>
									<Link className='ProductsRoot_li_2' to={arc.path.base+'baking-handling/#unbaked-fruit-pies/'} target='_blank'>Unbaked Fruit Pies
									</Link>
								</div>
								<div className='ProductRoot_li_2'>
									<Link className='ProductsRoot_li_2' to={arc.path.base+'baking-handling/#brownie-batter/'} target='_blank'>Brownie Batter
									</Link>
								</div>								
							</div>
						</div>						
					</div>
					<div className='container_main'>
						{
							this.props.location.query.q ? <Products {...this.props} cursor={this.props.cursor.select('branch')}/> : React.cloneElement(this.props.children, {cursor: this.props.cursor.select('branch')})
						}
					</div>
				</div>
			</div>
		);
	},
}));
