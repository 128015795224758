import React from 'react';
import tree from './tree';
import { Router, Route, IndexRoute } from 'react-router';
import Root from './components/Root';
import qs from 'qs';
import { createHistory } from 'history';

import Home from './components/Home';
import ProductsRoot from './components/ProductsRoot';
import Categories from './components/Categories';
import Subcategories from './components/Subcategories';
import ProductDetail from './components/ProductDetail';
import Events from './components/Events';
import Supplies from './components/Supplies';
import SupplyProduct from './components/SupplyProduct';
import Contact from './components/Contact';
import StandardPage from './components/StandardPage';
import Error404 from './components/Error404';

const history = createHistory();

function createElement(Component, props) {
	if (Component === Root) {
		return <Component cursor={tree.select('root')} isLoading={tree.select('isLoading').get()} {...props}/>;
	} else {
		return <Component {...props}/>;
	}
}

export default (
	<Router history={history} createElement={createElement} stringifyQuery={(query) => qs.stringify(query, { arrayFormat: 'repeat' })}>
		<Route path={arc.path.base} component={Root}>
			<IndexRoute component={Home}/>
			<Route path='products' component={ProductsRoot}>
				<IndexRoute component={Categories}/>
				<Route path=':categoryCode'>
					<IndexRoute component={Subcategories}/>
					<Route path=':subcategoryCode' component={Subcategories}/>
				</Route>
			</Route>
			<Route path='product/:productId' component={ProductDetail}/>
			<Route path='events' component={Events}/>
			<Route path='supplies/:sku' component={SupplyProduct}/>
			<Route path='supplies' component={Supplies}/>
			<Route path='contact' component={Contact}/>
			<Route path=':pageCode' component={StandardPage}/>
			<Route path='*' component={Error404}/>
		</Route>
	</Router>
);