import React from 'react';
import Link from '../Link';
import Control from 'react-control';

export default React.createClass({
	getList(categories) {
		const tree = {};
		const list = categories.filter(el => el.SUBCATEGORY == null).map(el => {
			const top = {
				cat: el,
				children: [],
			};
			tree[el.CATEGORY] = top;
			return top;
		});

		categories.filter(el => el.SUBCATEGORY).forEach(el => {
			if (tree[el.CATEGORY]) {
				tree[el.CATEGORY].children.push(el);
			}
		});

		return list;
	},
	getInitialState() {
		const list = this.getList(this.props.categories);
		const openCategories = {};

		list.filter(el => this.props.pathname.indexOf(arc.path.base+'products/'+el.cat.CATEGORY) === 0).forEach(el => {
			openCategories[el.cat.CATEGORY] = true;
		});

		return {
			openCategories,
			list,
		};
	},
	toggleSubnav(categoryCode) {
		var openCategories = Object.assign({}, this.state.openCategories);

		if (openCategories.hasOwnProperty(categoryCode)) {
			delete openCategories[categoryCode];
		} else {
			openCategories[categoryCode] = true;
		}
		this.setState({openCategories});
	},
	componentWillReceiveProps(nextProps) {
		if (nextProps.pathname !== this.props.pathname) {
			this.state.list.forEach(el => {
				if (nextProps.pathname.indexOf(arc.path.base+'products/'+el.cat.CATEGORY) === 0) {
					var openCategories = Object.assign({}, this.state.openCategories);
					openCategories[el.cat.CATEGORY] = true;
					this.setState({openCategories});
				}
			});
		}
	},
	render() {
		if (!this.props.categories.length) return null;

		return (
			<ul className='CategoryFilter'>
			{
				this.state.list.map((el,i) => {
					const isOpen = this.state.openCategories.hasOwnProperty(el.cat.CATEGORY);
					const isActive = this.props.pathname == arc.path.base+'products/'+el.cat.CATEGORY;

					return (
						<li key={i}>
							<div className={'CategoryFilter_main '+(isActive ? 'active' : '')}>
								<Link onClick={(e)=> {
									if (isActive) {
										e.preventDefault();
										this.toggleSubnav(el.cat.CATEGORY);
									}
								}} to={arc.path.base+'products/'+el.cat.CATEGORY}>
									{el.cat.TITLE}
								</Link>
								{el.children.length ? (
									<Control className='CategoryFilter_toggle' onClick={()=> this.toggleSubnav(el.cat.CATEGORY)}>
										<i className={'fa '+(isOpen ? 'fa-chevron-up' : 'fa-chevron-down')}/>
									</Control>
								) : null}
							</div>
							{
								el.children.length && isOpen ? (
									<ul className='CategoryFilter_sub'>
										{el.children.map((sub,i) => {
											const isActive = this.props.pathname == arc.path.base+'products/'+sub.CATEGORY+'/'+sub.SUBCATEGORY;

											return (
												<li className={isActive ? 'active' : ''} key={i}>
													<Link to={arc.path.base+'products/'+sub.CATEGORY+'/'+sub.SUBCATEGORY}>{sub.TITLE}</Link>
												</li>
											);
										})}
									</ul>
								) : null
							}
						</li>
					);
				})
			}
			</ul>
		);
	},
});
