import React from 'react';
import DocTitle from 'react-document-title';
import ajax from 'arcdynamic-request';
import currency from '../../currency';
import ReactiveWrapper from '../ReactiveWrapper';
import Paginate from '../Paginate';
import Results from '../Results';
import Image from 'arc-dynamic-img';
import imgSrc from 'arcdynamic-resize';
import SortTable from '../SortTable';
import Link from '../Link';
import Alert from '../Alert';
import {History} from 'react-router';
import Control from 'react-control';

const limitCount = 36;

export default ReactiveWrapper(React.createClass({
	mixins: [
		History,
	],
	getInitialState() {
		return {
			isFetching: true,
			products: [],
			count: false,
			view: 'grid',
			categories: [],
		};
	},
	getCategories() {
		ajax(arc.path.store, {
			service: 'cart',
			action: 'store.Category.getTree',
			options: {}
		}).then(res => {
			this.setState({
				categories: res.data || [],
			})
		});
	},
	fetch(query) {
		this.setState({isFetching: true});

		const page = Number(query.page) || 1;

		ajax(arc.path.store, {
			service: 'cart',
			action: 'store.Product.get',
			options: {
				count: 2,
				filter: {
					quantityStatusId: 1,
					search: query.search || null,
					categoryCode: query.category || 'supplies',
				},
				flag: {
					file: true,
					attributeValue: true,
				},
				order: query.order || 'category-asc',
				limit: {
					count: limitCount,
					offset: page * limitCount - limitCount,
				},
			}
		}).then(res => {
			this.setState({
				products: res.data || [],
				count: res.count || false,
				isFetching: false,
			})
		});
	},
	search(e) {
		const query = Object.assign({}, this.props.location.query);
		if (e.target.value) {
			query.search = e.target.value;
		} else {
			delete query.search;
		}
		delete query.page;
		this.history.pushState(null, this.props.location.pathname, query);
	},
	setCategory(code) {
		const query = Object.assign({}, this.props.location.query);
		if (code) {
			query.category = code;
		} else {
			delete query.category;
		}
		delete query.page;
		this.history.pushState(null, this.props.location.pathname, query);
	},
	clearSearch() {
		const query = Object.assign({}, this.props.location.query);
		delete query.search;
		delete query.page;
		this.history.pushState(null, this.props.location.pathname, query);
	},
	componentDidMount() {
		this.fetch(this.props.location.query);
		this.getCategories();
	},
	componentWillReceiveProps(nextProps) {
		if (nextProps.location.query !== this.props.location.query) {
			this.fetch(nextProps.location.query);
		}
	},
	toggleView() {
		const view = this.state.view === 'grid' ? 'list' : 'grid';
		this.setState({view});
	},
	setOrder(order) {
		const query = Object.assign({}, this.props.location.query);
		query.order = order;
		delete query.page;
		this.history.pushState(null, this.props.location.pathname, query);
	},
	listView(products) {
		if (!products.length) return null;

		const query = this.props.location.query;

		let columns = [
			{
				name: 'Name',
				asc: 'a-z',
				desc: 'z-a',
			},
			{
				name: 'SKU',
			},
			{
				name: 'Price',
				asc: 'price-asc',
				desc: 'price-desc',
			},
		];

		let rows = products.map((x)=>{
			const thumb = Array.isArray(x.files) && x.files.filter(x => x.type === 'image').shift();
			return (
				<tr key={x.sku}>
					<td className='w-full strip'>
						<div className='Supplies_list_item'>
							<Link className='Supplies_list_item_img' to={'/supplies/'+x.sku}>
								<Image width='80' src={thumb ? imgSrc(thumb.url, 80) : null}/>
							</Link>
							<div className='Supplies_list_item_body'>
								<Link to={'/supplies/'+x.sku} className='Supplies_list_item_body_name'>{x.name}</Link>
								{
									x.attributeValues && x.attributeValues.length ? (
										<div className='Supplies_list_item_body_attr'>
											{x.attributeValues.map(el => el.name).join(' / ')}
										</div>
									) : null
								}
								{x.descBrief ? <div className='Supplies_list_item_body_desc'>{x.descBrief}</div> : null }
							</div>
						</div>
					</td>
					<td className='nowrap'>{x.sku}</td>
					<td className='nowrap'>{currency(x.price)}</td>
				</tr>
			)
		});

		return (
			<div className='Supplies_list'>
				<SortTable order={query.order} setOrder={this.setOrder} columns={columns} rows={rows} />
			</div>
		);
	},
	gridView(products) {
		if (!products.length) return null;

		return (
			<div className='grid-view'>
				{products.map(x => {
					const thumb = Array.isArray(x.files) && x.files.filter(x => x.type === 'image').shift();

					return (
						<Link key={x.sku} className='Supplies_mini' to={'/supplies/'+x.sku}>
							<div className='Supplies_mini_img'>
								<div className='Supplies_mini_img_wrap'>
									<Image src={thumb ? imgSrc(thumb.url, 300) : null}/>
								</div>
							</div>
							<div className='Supplies_mini_body'>
								<div className='Supplies_mini_body_name'>{x.name}</div>
								<div className='Supplies_mini_body_price'>{currency(x.price)}</div>
							</div>
						</Link>
					)
				})}
			</div>
		);
	},
	render() {
		const products = this.state.products;
		const count = this.state.count;
		const page = Number(this.props.location.query.page) || 1;

		return (
			<div className='Supplies'>
				<DocTitle title='Davids Cookies - Supplies'/>
				<div className='container'>
					<div className='container_side no-print'>
						<div className='Supplies_filter'>
							{
								this.state.categories.map(x => (
									<div key={x.id}>
										<div className='Supplies_filter_header'>
											<button type='button' onClick={()=>this.setCategory(false)}>{x.name}</button>
										</div>
										{
											x.children && x.children.length && (
												<ul>
													{
														x.children.map(c => (
															<li key={c.id}>
																<button type='button' className={c.code === this.props.location.query.category ? 'active' : ''} onClick={()=>this.setCategory(c.code === this.props.location.query.category ? false : c.code)}>{c.name}</button>
															</li>
														))
													}
												</ul>
											)
										}
									</div>
								))
							}
							<Link to='/merchandising#print-your-own-nutrition-ingredient-labels'>Print Your Own Nutritional Labels</Link>
						</div>
					</div>
					<div className='container_main'>
							<h1>Supplies</h1>
							<div className='Supplies_message'>
								<p>Welcome to the David's Cookies Supply Store!</p>
								<p>We offer a large variety of displays, branded stickers and bags  - everything you need to create a strong Grab & Go/Carry-Out bakery program in your operation. </p>
								<p>Orders placed in the store will be shipped out on Thursdays and Fridays only.</p>
								<p>As always, we will continue to offer our David’s Cookies branded wax bags and stickers free of charge. (Shipping charges still apply.)</p>
								<p>Should you need nutritional labels to apply to products you wrap for individual sale, our “<a href="/merchandising#print-your-own-nutrition-ingredient-labels">Print Your Own Nutritional Labels</a>” tool can be accessed by clicking the link on this page or going to the “Merchandising Tools” section of this website. Simply scroll down the page to find the ‘Label Creator’ and enter the 5-digit item code.  Please note that we still have available a limited supply of discounted preprinted nutritional labels which you can order below.</p>
								<p>Thanks for shopping with us and thank you for being a Valued Customer of David’s Cookies!</p>
							</div>
							<div className='Supplies_nav'>
								<div className='Supplies_nav_results' style={this.state.isFetching && count === false ? {visibility: 'hidden'} : null}>
									<Results className='flex-1' count={limitCount} page={page} itemCount={products.length} totalCount={count} query={this.props.location.query.search} clearSearch={this.clearSearch}/>
								</div>
								<form className='Supplies_nav_search'>
									<input type='text' value={this.props.location.query.search} onChange={this.search} placeholder='Search'/>
								</form>
								<Control className='tooltip' onClick={this.toggleView} aria-label={this.state.view === 'grid' ? 'Switch to list view' : 'Switch to grid view'}>
									<i className={'icon-btn fa '+(this.state.view === 'grid' ? 'fa-th-list' : 'fa-th')}/>
								</Control>
							</div>
							{
								this.state.view === 'list' ? this.listView(products) : this.gridView(products)
							}
							{
								count ? (
									<div className='Supplies_page'>
										<Paginate query={{}} pathname={this.props.location.pathname} count={limitCount} page={page} itemCount={products.length} totalCount={count}/>
									</div>
								) : null
							}
					</div>
				</div>
			</div>
		);
	},
}));
