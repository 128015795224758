import React from 'react';

export default React.createClass({
	getDefaultProps() {
		return {
			required: false,
			label: null,
			htmlFor: null,
		};
	},
	render() {
		return (
			<dl className={'field '+(this.props.required ? 'required' : '')}>
				<dt><label htmlFor={this.props.htmlFor}>{this.props.label}</label></dt>
				<dd>{this.props.children}</dd>
			</dl>
		);
	},
});