import React from 'react';
import ReactDOM from 'react-dom';
import Image from 'arc-dynamic-img';
import Control from 'react-control';

let groups = {};

const Portal = React.createClass({
	propTypes: {
		parentNode: React.PropTypes.object,
	},
	componentDidMount() {
		this.node = document.createElement('div');
		this.parentNode = this.props.parentNode || document.body;
		this.parentNode.appendChild(this.node);
		ReactDOM.render(this.props.children, this.node);
	},
	componentWillUnmount() {
		ReactDOM.unmountComponentAtNode(this.node);
		this.parentNode.removeChild(this.node);
	},
	componentWillReceiveProps: function(newProps) {
		ReactDOM.render(newProps.children, this.node);
	},
	render() {
		return null;
	},
});

const Modal = React.createClass({
	getInitialState: function() {
		const opts = this.props.opts;
		let group = opts.group && groups[opts.group] ? groups[opts.group] : [opts];

		return {
			group: group,
			index: group.indexOf(opts),
		};
	},
	componentWillReceiveProps(nextProps) {
		const opts = nextProps.opts;
		let group = opts.group && groups[opts.group] ? groups[opts.group] : [opts];

		this.setState({
			group: group,
			index: group.indexOf(opts),
		});
	},
	componentDidMount() {
		this.refs.self.focus();

		// Disable the document scrollbar & prevent layout shift
		const root = document.querySelector('html');
		root.style.paddingRight = (window.innerWidth - document.documentElement.clientWidth)+'px';
		root.style.overflow = 'hidden';

		// Remember the currently focused element
		this.activeElement = document.activeElement;

		// Close on Esc keypress
		document.addEventListener('keydown', this.handleKeyDown);
	},

	showNext() {
		let index = this.state.index+1 <= this.state.group.length-1 ? this.state.index+1 : 0;
		this.setState({index});
	},
	showPrev() {
		let index = this.state.index-1 >= 0 ? this.state.index-1 : this.state.group.length-1;
		this.setState({index});
	},
	handleKeyDown(e) {
		switch (e.keyCode) {
			case 27: // esc
				e.preventDefault();
				this.onClose();
				break;
			case 37: // left-arrow
				if (this.state.group.length > 1) {
					e.preventDefault();
					this.showPrev();
				}
				break;
			case 39: // right-arrow
				if (this.state.group.length > 1) {
					e.preventDefault();
					this.showNext();
				}
				break;
		}
	},
	componentWillUnmount() {
		// Enable scrollbar
		const root = document.querySelector('html');
		root.style.overflow = '';
		root.style.paddingRight = '';

		// Restore previously focused element
		if (this.activeElement) {
			this.activeElement.focus();
		}

		// Unbind events
		document.removeEventListener('keydown', this.handleKeyDown);
	},
	onClose() {
		this.props.onClose();
	},
	render() {
		if (this.state.index == null) return null;

		let opts = this.state.group[this.state.index];
		let prev = null;
		let next = null;
		let count = null;

		if (this.state.group.length > 1) {
			next = <Control className='Lightbox_next' onClick={this.showNext}><i className='fa fa-chevron-right'/></Control>
			prev = <Control className='Lightbox_prev' onClick={this.showPrev}><i className='fa fa-chevron-left'/></Control>
			count = <div className='Lightbox_index'>{(this.state.index+1).toLocaleString()+' of '+this.state.group.length.toLocaleString()}</div>;
		}

		return (
			<div ref='self' className='Lightbox' tabIndex='0'>
				<div className='Lightbox_aside'>
					{prev}
					{next}
					{count}
					<div className='Lightbox_title'><span>{opts.alt}</span></div>
					<Control className='Lightbox_close' onClick={this.onClose}><i className='fa fa-times'/></Control>
				</div>
				<div className='Lightbox_main' onClick={this.onClose}>
					<Image className='Lightbox_img' src={opts.src} alt={opts.alt}/>
				</div>
			</div>
		);
	}
});

export default React.createClass({
	statics: {
		add(opts) {
			if (opts.group) {
				if (!groups[opts.group]) {
					groups[opts.group] = [];
				}
				groups[opts.group].push(opts);
			}
		},
		remove(opts) {
			if (opts.group) {
				groups[opts.group].splice(groups[opts.group].indexOf(opts),1);
			}
		},
	},
	render() {
		return <Portal><Modal {...this.props}/></Portal>;
	},
});