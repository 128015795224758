import React from 'react';
import ReactiveWrapper from '../ReactiveWrapper';
import Products from '../Products';
import DocTitle from 'react-document-title';
import ajax from 'arcdynamic-request';
import Image from 'arc-dynamic-img';
import Link from '../Link';
import Editable from '../Editable';
import legacyMedia from '../../legacy-media';
import imgSrc from 'arcdynamic-resize';

function fetchData(categoryCode) {
	return ajax(arc.path.api, {
		service: 'arcimedes',
		action: 'open.datasource.table.Data.getData',
		params: ['code', 'DAVIDSDATACATEGORIES', {
			filter: [
				{
					text: categoryCode,
					code: 'CATEGORY',
					type: 'exact',
				},
			],
			limit: {
				count: 1000,
			},
		}],
	}, {cache: true}).then((res)=>{
		return {
			subcategories: res && res.success ? res.data : []
		}
	})
}

export default ReactiveWrapper(React.createClass({
	statics: {
		fetch(props) {
			return Promise.all([
				fetchData(props.params.categoryCode)
			]);
		}
	},
	componentWillReceiveProps(nextProps) {
		if (this.props.params.categoryCode !== nextProps.params.categoryCode) {
			this.props.cursor.select('subcategories').set(null);

			Promise.all([
				fetchData(nextProps.params.categoryCode),
			]).then(values => {
				if (this.isMounted()) {
					this.props.cursor.set(Object.assign({}, ...values));
				}
			})
		}
	},
	renderSubcategories(category, subcategories) {
		return (
			<div className='Subcategories'>
				<DocTitle title={category.TITLE}/>
				<h2>{category.TITLE}</h2>
				{
					category.SUMMARY ? (
						<Editable html={category.SUMMARY} enabled={this.props.isAdmin} onSave={(html)=> this.onSave(category.ARCIMEDES_ROW_ID, 'SUMMARY',html)}/>
					) : null
				}
				<ul className='Subcategories_list'>
					{
						subcategories.map((el,i) => {
							return (
								<div key={i} className='Subcategories_media'>
									<Link className='Subcategories_media_img' to={arc.path.base+'products/'+el.CATEGORY+'/'+el.SUBCATEGORY}>
										<Image src={el.IMAGE ? imgSrc(legacyMedia(el.IMAGE), 140) : null} width='140'/>
									</Link>
									<div className='Subcategories_media_body'>
										<h3>{el.TITLE}</h3>
										<div className='Subcategories_media_body_content'>
											{el.SUMMARY ? el.SUMMARY : null}
										</div>
										<div className='Subcategories_media_body_button'>
											<Link className='button' to={arc.path.base+'products/'+el.CATEGORY+'/'+el.SUBCATEGORY}>Search</Link>
										</div>
									</div>
								</div>
							);
						})
					}
				</ul>
			</div>
		);
	},
	onSave(ARCIMEDES_ROW_ID, column, content) {
		return ajax(arc.path.api, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.updateByTableCode',
			params: ['DAVIDSDATACATEGORIES', {
				value: [
					{ARCIMEDES_ROW_ID, [column]: content},
				],
			}],
		}, {cache: true}).then((res)=>{
			// todo
		});
	},
	renderProducts(category) {	
		return (
			<div>
				{
					category ? (
						<div>
							<DocTitle title={category.TITLE}/>
							<h2>{category.TITLE}</h2>
							{
								category.DETAILS ? (
									<div className='Subcategories_details'>
										<Editable html={category.DETAILS} enabled={this.props.isAdmin} onSave={(html)=> this.onSave(category.ARCIMEDES_ROW_ID, 'DETAILS',html)}/>
									</div>		
								) : null
							}
						</div>
					) : null
				}
				<Products cursor={this.props.cursor.select('products')} params={this.props.params} location={this.props.location} />
			</div>
		);
	},
	render() {
		if (this.props.subcategories == null) return null;

		const category = this.props.subcategories.filter(el => el.SUBCATEGORY == null).pop();
		const subcategories = this.props.subcategories.slice(0)

		subcategories.splice(subcategories.indexOf(category), 1);

		if (this.props.params.subcategoryCode) {
			const subcat = subcategories.filter(el => el.SUBCATEGORY === this.props.params.subcategoryCode).pop();
			return this.renderProducts(subcat);
		} else if (subcategories.length) {
			return this.renderSubcategories(category, subcategories);
		} else {
			return this.renderProducts(category);
		}
	},
}));