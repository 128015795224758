import React from 'react';
import Link from '../Link';
import MiniSearch from '../MiniSearch';

export default React.createClass({
	render() {
		return (
			<header className='Header'>
				<div className='Header_wrap'>
					<Link className='Header_logo' to={arc.path.base}>
						<img src={arc.path.arcMedia+'food-services/img/logo-white.svg'} height='108' alt='Home'/>
					</Link>
					<div className='Header_main'>
						<div className='Header_account'>
							<a className='Header_account_item' href={arc.path.cart+'account'}>Account</a>
							<div className='Header_account_div'/>
							<a href={arc.path.cart} className='Header_account_cart' aria-label='Cart'>
								<img className='Header_account_cart_icon' src={'/img/shopping-bag.svg'} height='65' alt='My Bag'/>
								{this.props.productCount ? <span className='Header_account_cart_count'>{this.props.productCount}</span> : null}
							</a>
						</div>
						<MiniSearch
							className='MainNav_item MainNav_item--search'
							query={this.props.query}
						/>
					</div>
				</div>
				<center><img className='print-only Header_logoprint' src={arc.path.arcMedia+'food-services/img/logo.svg'} width='150'/></center>
			</header>
		);
	}
});