import React from 'react';
import DocTitle from 'react-document-title';
import Header from '../Header';
import MainNav from '../MainNav';
import LoadingIndicator from '../LoadingIndicator';
import ajax from 'arcdynamic-request';

export default React.createClass({
	getInitialState() {
		return {
			cart: null,
		};
	},
	handleKeyDown(e) {
		// Scheme to only show the focus outline when the user has used keyboard navigation to focus an element.
		if (e.key === 'Tab') {
			setTimeout(()=>{
				if (this.activeElement !== document.activeElement) {
					if (this.activeElement) {
						this.activeElement.removeAttribute('data-focused');
					}
					if (document.activeElement) {
						this.activeElement = document.activeElement;
						this.activeElement.setAttribute('data-focused', true);
					}
				}
			}, 0);
		}
	},
	getCart() {
		ajax(arc.path.store, {
			service: 'cart',
			action: 'store.Cart.get',
		}).then((res)=>{
			this.setCart(res.data);
		});
	},
	setCart(cart, callback) {
		this.setState({cart}, callback);
	},
	componentDidMount() {
		this.getCart();

		document.addEventListener('visibilitychange', ()=>{
			if (!document.hidden) {
				this.getCart();
			}
		});
	},
	render() {
		return (
			<div className='Root' onKeyDown={this.handleKeyDown}>
				<DocTitle title="David's Cookies"/>
				<Header query={this.props.location.query} path={this.props.location.pathname} productCount={this.state.cart ? this.state.cart.productCount : null}/>
				<MainNav cursor={this.props.cursor.select('nav')} />
				{React.cloneElement(this.props.children, {
					cursor: this.props.cursor.select('body'),
					cart: this.state.cart,
					setCart: this.setCart,
				})}
				<LoadingIndicator isLoading={this.props.isLoading}/>
			</div>
		);
	},
});