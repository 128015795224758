import React from 'react';
import TagButton from '../TagButton';

export default React.createClass({
	propTypes: {
		count: React.PropTypes.number.isRequired,
		page: React.PropTypes.number.isRequired,
		totalCount: React.PropTypes.oneOfType([
			React.PropTypes.number,
			React.PropTypes.bool,
		]).isRequired,
		itemCount: React.PropTypes.number.isRequired,
		query: React.PropTypes.string,
		clearSearch: React.PropTypes.func.isRequired,
	},
	getDefaultProps() {
		return {
			count: 0,
			page: 1,
			itemCount: 0,
			totalCount: 0,
			query: null,
			clearSearch: null,
		}
	},
	render() {
		let showing = null;

		if (this.props.itemCount > 0){
			const start = (this.props.page * this.props.count) - this.props.count + 1;
			const end = start + this.props.itemCount - 1;

			showing = (
				<span>
					Showing {start.toLocaleString()}–{end.toLocaleString()} {this.props.totalCount ? ' of '+this.props.totalCount.toLocaleString() : null}
					{
						this.props.query ? (
							<span>
								{this.props.itemCount === 1 ? ' result for ' : ' results for '}
								<TagButton onClick={this.props.clearSearch}>{this.props.query}</TagButton>
							</span>
						) : null
					}
				</span>
			);
		} else {
			if (this.props.query) {
				showing = (
					<span>No results for <TagButton onClick={this.props.clearSearch}>{this.props.query}</TagButton></span>
				);
			} else {
				showing = (<span>No results.</span>);
			}
		}

		return (
			<div className={this.props.className || ''}>
				{showing}
			</div>
		);
	}
});