export default {
	fillQuery(query) {
		// This method sets the default values
		const q = Object.assign({
			q: null,
			order: null,
			page: null,
		}, query || {});

		q.page = Number(q.page) || 1;

		return q;
	},
	cleanQuery(query) {
		// This method trims the properties with default values from the query object, for a cleaner querystring.
		const q = Object.assign({}, query || {});

		Object.keys(q).forEach(key => {
			if (!q[key]) {
				delete q[key];
			}
		});

		if (q.page === 1) {
			delete q.page;
		}

		return q;
	},
	setSearch(search) {
		const q = this.fillQuery(this.props.location.query);

		if (q.q !== search) {
			q.q = search;
			delete q.page;
			this.history.pushState(null, this.props.location.pathname, this.cleanQuery(q));
		}
	},
	clearSearch() {
		const q = Object.assign({}, this.props.location.query);

		delete q.page;
		delete q.q;
		this.history.pushState(null, this.props.location.pathname, q);
	},
	setOrder(order) {
		const q = this.fillQuery(this.props.location.query);

		if (q.order !== order) {
			q.order = order;
			delete q.page;
			this.history.pushState(null, this.props.location.pathname, this.cleanQuery(q));
		}
	},
};
