import React from 'react';
import {History} from 'react-router';

export default React.createClass({
	mixins: [
		History,
	],
	getDefaultProps() {
		return {
			placeholder: {
				full: 'Search the GOOD STUFF!',
				short: 'Search',
			},
			action: arc.path.base+'products',
		};
	},
	getInitialState() {
		return {
			hasFocus: false,
			size: 'full',
		};
	},
	componentWillUpdate(nextProps) {
		const nextQuery = nextProps.query.q

		if (nextQuery !== this.props.query.q) {
			this.refs.q.value = nextQuery || '';
		}
	},
	componentDidMount() {
		this.resize();
		window.addEventListener('resize', this.resize);
	},
	componentWillUnmount() {
		window.removeEventListener('resize', this.resize);
	},
	resize() {
		let style = window.getComputedStyle(this.refs.main, null);
		this.setState({
			size: parseInt(style.width, 10) > 215 ? 'full' : 'short'
		})
	},
	handleSubmit(e) {
		e.preventDefault();
		const q = this.props.query;
		q.q = this.refs.q.value;
		delete q.offset;
		this.history.pushState(null, this.props.action, q);
	},
	render() {
		return (
			<div ref='main' className={'MiniSearch '+(this.state.hasFocus ? 'MiniSearch--has-focus' : '')} onClick={()=> this.refs.q.focus()}>
				<form role='search' onSubmit={this.handleSubmit} action={this.props.action} method='get' className='table'>
					<div className='td v-align-middle w-full'>
						<input 
							aria-label='Search'
							type='text' 
							ref='q'
							className='MiniSearch_input' 
							onFocus={()=> this.setState({hasFocus: true})} 
							onBlur={()=> this.setState({hasFocus: false})} 
							placeholder={this.props.placeholder[this.state.size]}
							defaultValue={this.props.query.q}
						/>
					</div>
					<div className='td v-align-middle' onClick={this.handleSubmit}>
						<i className='MiniSearch_icon fa fa-search'/>
					</div>
				</form>
			</div>
		);
	}
});