import React from 'react';
import ajax from 'arcdynamic-request';

export default React.createClass({
	getDefaultProps() {
		return {
			selected: [],
		};
	},
	statics: {
		fetch() {
			return Promise.all([
				ajax(arc.path.api, {
					service: 'arcimedes',
					action: 'open.datasource.table.Data.getData',
					params: ['code', 'DAVIDSBROKERS', {
						limit: {
							count: 1000,
						},
					}],
				}, {cache: true}).then((res)=>{
					return {
						brokers: res && res.success ? res.data : []
					}
				}),
			]);
		}
	},
	handleChange(e) {
		this.props.cursor.select('selected').set(this.props.brokers.filter((el)=> el.STATE === e.target.value));
	},
	render() {
		if (this.props.brokers === undefined) return null;

		return (
			<div>
				<div className='m-b-block'>
					<h3 className='inline-block m-r-small' style={{margin: 0, fontWeight: 'bold'}}>Select a state:</h3>
					<select onChange={this.handleChange}>
						<option></option>
						{this.props.brokers.map(el => el.STATE).filter((el, i, arr)=> arr.indexOf(el) === i).sort().map((state,i)=> <option key={i} value={state}>{state}</option>)}
					</select>
				</div>
				{ this.props.selected.length ? (
					<table>
						<thead>
							<tr>
								<th>Broker</th>
								<th>Broker Address</th>
								<th>David's Sales Rep</th>
							</tr>
						</thead>
						<tbody>
						{
							this.props.selected.map((el,i)=>{
								return (
									<tr key={i}>
										<td>
											<div><strong>{el.BROKER_NAME}</strong></div>
											<div>{el.CONTACT}</div>
											<div><a href={'mailto:'+el.BROKER_EMAIL}>{el.BROKER_EMAIL}</a></div>
											<div>{el.TELEPHONE}</div>
										</td>
										<td>
											<div>{el.ADDRESS}</div>
											<div>{el.CITY_STATE_ZIP}</div>
										</td>
										<td>
											<div>{el.DAVIDS_SALES_REPRESENTATIVE}</div>
											<div><a href={'mailto:'+el.SALES_REP_EMAIL}>{el.SALES_REP_EMAIL}</a></div>
											<div>{el.SALES_REP_TELEPHONE}</div>
										</td>
									</tr>
								);
							})
						}
						</tbody>
					</table>
				) : null}
			</div>
		);
	}
});