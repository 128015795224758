import ajax from 'arcdynamic-request';
import React from 'react';
import {History} from 'react-router';
import Link from '../Link';
import Results from '../Results';
import Paginate from '../Paginate';
import SortTable from '../SortTable';
import MiniProduct from '../MiniProduct';
import Image from 'arc-dynamic-img';
import Control from 'react-control';
import queryMixin from '../../mixins/query';
import imgSrc from 'arcdynamic-resize';

const limitCount = 24;

function getItems(params, query) {
	const categoryFilter = [];
	const columnFilter = (/[^\d]/.test(query.q)) ? 'KEYWORDS' : 'ITEMID';

	query = queryMixin.fillQuery(query);

	if (params.categoryCode) {
		const f = {
			text: params.categoryCode,
			code: 'CATEGORY',
			type: 'exact',
		};

		categoryFilter.push(f);
	}

	if (params.subcategoryCode) {
		const f = {
			text: params.subcategoryCode,
			code: 'SUBCATEGORY',
			type: 'exact',
		};

		categoryFilter.push(f);
	}

	const filter = categoryFilter.slice(0);

	if (query.q) {
		const f = {
			text: query.q,
			code: columnFilter,
			type: 'match',
		};
		
		categoryFilter.push(f);
		filter.push(f);
	}

	if (query.filter) {
		filter.push({
			text: query.filter,
			code: 'FILTER',
			type: 'exact',
		});
	}

	const order = [];

	switch (query.order) {
		case 'weight-asc':
			order.push({
			code: 'UNIT_WEIGHT',
			type: 'asc',
		})
			break;
		case 'weight-desc':
			order.push({
				code: 'UNIT_WEIGHT',
				type: 'desc',
			})
			break;
		case 'name-desc':
			order.push({
				code: 'PRODUCT_NAME',
				type: 'desc',
			})
			break;
		case 'name-asc':
			order.push({
				code: 'PRODUCT_NAME',
				type: 'asc',
			})
			break;
		case 'itemid-desc':
			order.push({
				code: 'ITEMID',
				type: 'desc',
			})
			break;
		case 'itemid-asc':
		default:
			order.push({
				code: 'ITEMID',
				type: 'asc',
			})
			break;
	}

	return Promise.all([
		ajax(arc.path.api, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.getData',
			params: [ 'code', 'DAVIDSDATAPRODUCTS', {
				filterType: 'and',
				filter,
				order,
				column: [
					'PRODUCT_NAME',
					'ITEMID',
					'UNITS_PER_CASE',
					'PORTIONS_PER_CASE',
					'UNIT_WEIGHT',
					'IMAGE_FILE_NAME',
					'MEDIA_FILE_URL',
				],
				limit: {
					count: limitCount,
					offset: (limitCount * query.page) - limitCount,
				},

			}],
		}, {cache: true}).then((res)=>{
			return res && res.success ? res.data : [];
		}),

		ajax(arc.path.api, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.getData',
			params: [ 'code', 'DAVIDSDATAPRODUCTS', {
				filterType: 'and',
				filter,
				count: true,
			}],
		}, {cache: true}).then((res)=>{
			return res && res.success ? res.data : [];
		}),

		ajax(arc.path.api, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.getData',
			params: [ 'code', 'DAVIDSDATAPRODUCTS', {
				filterType: 'and',
				filter: categoryFilter.concat({
					text: '',
					code: 'FILTER',
					type: 'notNull',
				}),
				order: [{
					code: 'FILTER',
					type: 'asc',					
				}],
				column: [
					'FILTER'
				],
				flag: {
					'distinct': true,
				},
				limit: {
					count: 1000,
				},
			}],
		}, {cache: true}).then((res)=>{
			if (res && res.success && res.data.length) {
				const key = Object.keys(res.data[0]).pop();
				return res.data.map(el => el[key]);
			} else {
				return [];
			}
		}),
	]).then(values => {
		return {
			items: values[0],
			totalCount: values[1],
			filter: values[2],
		}
	});
}

export default React.createClass({
	mixins: [
		History,
		queryMixin,
	],
	getDefaultProps() {
		return {
			view: 'list',
		};
	},
	statics: {
		fetch(props) {
			return Promise.all([
				getItems(props.params, props.location.query),
			]);
		}
	},
	componentWillReceiveProps(nextProps) {
		if (nextProps.location.query !== this.props.location.query) {
			this.props.cursor.select('items').set(null);

			Promise.all([
				getItems(nextProps.params, nextProps.location.query),
			]).then(values => {
				if (this.isMounted) {
					values.forEach(obj => {
						Object.keys(obj).forEach(key => this.props.cursor.select(key).set(obj[key]));
					});
				}
			});
		}
	},
	listView(items) {
		if (!items.length) return null;
		
		const q = this.fillQuery(this.props.location.query);

		let columns = [
			{
				name: 'Name',
				asc: 'name-asc',
				desc: 'name-desc',
			},
			{
				name: 'Item#',
				asc: 'itemid-asc',
				desc: 'itemid-desc',
			},
			{
				name: 'Weight',
				asc: 'weight-asc',
				desc: 'weight-desc',
			},
			{
				name: 'Per Case',
			},
			{
				name: 'PDF',
			},
		];

		let rows = items.map((el, i)=>{
			if (!el.ITEMID) { return null; }

			let src = el.IMAGE_FILE_NAME && el.IMAGE_FILE_NAME !== '0' ? el.IMAGE_FILE_NAME : null;
			if (src && el.IMAGE_FILE_NAME.indexOf('http') !== 0) { // todo: eventually remove (backwards compatibility)
				src = arc.path.arcMedia+'img/products/'+el.IMAGE_FILE_NAME+'.jpg';
			}

			let weight = el.UNIT_WEIGHT;			

			if (weight && !isNaN(Number(weight))) {
				weight = Number(weight);

				if (weight < 16) {
					weight = weight+' oz';
				} else {
					weight = Number(Math.round((weight/16) + "e+1")  + "e-1")+' lbs';
				}
			}

			const pdfHref = el.MEDIA_FILE_URL || null;

			return (
				<tr key={i}>
					<td className='w-full strip'>
						<div className='Products_li'>
							<Link className='Products_li_img' to={arc.path.base+'product/'+el.ITEMID}>
								<Image width={80} src={imgSrc(src, 80)}/>
							</Link>
							<div className='Products_li_body'>
								<Link to={arc.path.base+'product/'+el.ITEMID} className='capitalize color-red'>
									{el.PRODUCT_NAME}
								</Link>
								{el.DESCRIPTION ? <p>{el.DESCRIPTION}</p> : null }
							</div>
						</div>
					</td>
					<td className='nowrap'>{el.ITEMID}</td>
					<td className='nowrap'>{weight}</td>
					<td className='nowrap'>{el.UNITS_PER_CASE}</td>					
					<td className='nowrap'>
						{
							pdfHref && (
								<Link className='Products_media' to={pdfHref} target='_blank'>
									<i className='TagButton_icon fa fa-file-pdf-o' />
								</Link>	
							)
						}						
					</td>
				</tr>
			);
		});

		return <SortTable order={q.order} setOrder={this.setOrder} columns={columns} rows={rows} />
	},
	gridView(items) {
		return (
			<div className='grid-view'>
				{items.map(el => <MiniProduct key={el.ITEMID} product={el}/>)}
			</div>
		);
	},
	toggleView() {
		let view = this.props.view === 'grid' ? 'list' : 'grid';
		this.props.cursor.select('view').set(view);
	},
	renderFilter(values) {
		let q = Object.assign({}, this.props.location.query);
		delete q.page;
		delete q.filter;

		return (
			<div>
				<div><strong>Filter by:</strong></div>
				<ul className='Products_filters'>
					<li>
						<Link disabled={this.props.location.query.filter == null} className='button' to={this.props.location.pathname} query={q}>All</Link>
					</li>
					{
						values.filter(el => el).map(el => {
							return (
								<li key={el}>
									<Link disabled={this.props.location.query.filter == el} className='button' to={this.props.location.pathname} query={Object.assign({}, q, {filter: el})}>{el}</Link>
								</li>
							);
						})
					}
				</ul>
			</div>
		);
	},
	render() {
		const items = this.props.items;
		const q = this.fillQuery(this.props.location.query);

		if (this.props.items == null) return null;

		return (
			<div className='Products'>
				{
					this.props.filter.length > 1 ? this.renderFilter(this.props.filter) : null
				}

				<div className='flex m-b-small reg-group'>
					<Results className='flex-1' count={limitCount} page={q.page} itemCount={this.props.items.length} totalCount={this.props.totalCount} query={q.q} clearSearch={this.clearSearch}/>

					<Control className='tooltip' onClick={this.toggleView} aria-label={this.props.view === 'grid' ? 'Switch to list view' : 'Switch to grid view'}>
						<i className={'icon-btn fa '+(this.props.view === 'grid' ? 'fa-th-list' : 'fa-th')}/>
					</Control>
				</div>

				{this.props.view === 'list' ? this.listView(items) : this.gridView(items)}

				{
					(q.page > 1 || this.props.items.length) ? (
						<div>
							<Paginate query={this.cleanQuery(q)} pathname={this.props.location.pathname} count={limitCount} page={q.page} itemCount={this.props.items.length} totalCount={this.props.totalCount}/>
						</div>
					) : null
				}
			</div>
		);
	}
});