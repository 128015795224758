import React from 'react';
import DocTitle from 'react-document-title';
import Broker from '../Broker/';
import ContactForm from '../ContactForm/';
import ajax from 'arcdynamic-request';
import Editable from '../Editable';

function getPage(pageCode) {
	return ajax(arc.path.api, {
		service: 'arcimedes',
		action: 'open.datasource.table.Data.getData',
		params: ['code', 'DAVIDSPAGES', {
			filter: [
				{
					text: pageCode,
					code: 'PAGECODE',
					type: 'exact',
				},
			],
			limit: {
				count: 1,
			},
		}],
	}, {cache: true}).then((res)=>{
		return {
			page: res && res.success ? res.data[0] : false,
		}
	});
}

export default React.createClass({
	statics: {
		fetch() {
			return Promise.all([
				getPage('contact'),
			]);
		}
	},
	onSave(column, content) {
		return ajax(arc.path.api, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.updateByTableCode',
			params: ['DAVIDSPAGES', {
				value: [
					{ARCIMEDES_ROW_ID: this.props.page.ARCIMEDES_ROW_ID, [column]: content},
				],
			}],
		}, {cache: true}).then(()=>{
			// todo
		});
	},
	render() {
		return (
			<div className='container'>
				<DocTitle title={this.props.page.TITLE}/>
				<div className='container_side'>
					<Editable html={this.props.page.ASIDE} enabled={this.props.isAdmin} onSave={(html)=> this.onSave('ASIDE',html)}/>						
				</div>
				<div className='container_main'>
					<Editable tag='h1' html={this.props.page.TITLE} enabled={this.props.isAdmin} whitelist={{}} onSave={(html, text)=> this.onSave('TITLE',text)}/>
					<hr/>
					<h2>Find a David's Cookies sales representative or broker near you</h2>
					<Broker cursor={this.props.cursor.select('broker')}/>
					<hr/>
					<h2>Contact us</h2>
					<ContactForm/>
				</div>
			</div>
		);
	}
});