import React from 'react';
import Error404 from '../Error404';
import ajax from 'arcdynamic-request';
import DocTitle from 'react-document-title';
import Editable from '../Editable';
import Contents from '../Contents';
import Merchandising from '../Merchandising';
import ReactiveWrapper from '../ReactiveWrapper';

function getPage(pageCode) {
	return ajax(arc.path.api, {
		service: 'arcimedes',
		action: 'open.datasource.table.Data.getData',
		params: ['code', 'DAVIDSPAGES', {
			filter: [
				{
					text: pageCode,
					code: 'PAGECODE',
					type: 'exact',
				},
			],
			limit: {
				count: 1,
			},
		}],
	}, {cache: true}).then((res)=>{
		return {
			page: res && res.success && res.data && res.data.length ? res.data[0] : false,
		}
	});
}

export default ReactiveWrapper(React.createClass({
	statics: {
		fetch(props) {
			return Promise.all([
				getPage(props.params.pageCode),
			]);
		}
	},
	componentDidMount() {
		setTimeout(()=>{
			if (window.location.hash) {
				const el = document.querySelector(window.location.hash);
				if (el && typeof el.scrollIntoView === 'function') {
					el.scrollIntoView();
				}
			}
		}, 500)
	},
	componentWillReceiveProps(nextProps) {
		if (this.props.params.pageCode !== nextProps.params.pageCode) {
			Promise.all([
				getPage(nextProps.params.pageCode),
			]).then(values => {
				if (this.isMounted()) {
					this.props.cursor.set(Object.assign({}, ...values));
				}
			})
		}
	},
	onSave(column, content) {
		return ajax(arc.path.api, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.updateByTableCode',
			params: ['DAVIDSPAGES', {
				value: [
					{ARCIMEDES_ROW_ID: this.props.page.ARCIMEDES_ROW_ID, [column]: content},
				],
			}],
		}, {cache: true}).then((res)=>{
			// todo
		});
	},
	componentDidUpdate(prevProps, prevState) {
		if (this.props.page !== prevProps.page) {
			if (this.refs.contents) {
				this.refs.contents.refresh();
			}
		}
	},
	render() {
		if (this.props.page == null) return null;

		if (this.props.page === false) return <Error404/>;

		let content = null;
		if (this.props.isAdmin) {
			content = <Editable html={this.props.page.MAIN} enabled={this.props.isAdmin} onSave={(html)=> this.onSave('MAIN',html)}/>
		}
		else if (this.props.page.MAIN.indexOf('{{MERCHANDISING_LABELS}}') !== -1) {
			content = this.props.page.MAIN.split('{{MERCHANDISING_LABELS}}').map(x => (
				<div dangerouslySetInnerHTML={{__html: x}}/>
			))

			content.splice(1,0,<Merchandising/>)
		} else {
			content = <div dangerouslySetInnerHTML={{__html: this.props.page.MAIN}}/>
		}

		return (
			<div>
				<DocTitle title={this.props.page.TITLE}/>
				<div className='container'>
					<div className='container_side no-print'>
					{
						!this.props.isAdmin ? <Contents ref='contents' hash={this.props.location.hash}/> : null
					}
					</div>
					<div className='container_main'>
						<Editable tag='h1' html={this.props.page.TITLE} enabled={this.props.isAdmin} whitelist={{}} onSave={(html, text)=> this.onSave('TITLE',text)}/>
						<hr/>
						{content}
					</div>
				</div>
			</div>
		);
	}
}));
