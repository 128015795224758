import React from 'react';
import ReactiveWrapper from '../ReactiveWrapper';
import legacyMedia from '../../legacy-media';

export default ReactiveWrapper(React.createClass({
	getDefaultProps() {
		return {
			html: '',
			tag: 'div',
		};
	},
	render() {
		return React.createElement(this.props.tag, {
			dangerouslySetInnerHTML: {__html: this.props.html ? legacyMedia(this.props.html) : ''},
		});
	},
}));