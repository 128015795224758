import React from 'react';
import imgSrc from 'arcdynamic-resize';
import legacyMedia from '../../legacy-media';

export default React.createClass({
	getInitialState() {
		return {
			index: 0,
		}
	},
	componentDidMount() {
		this.cycle();
	},
	componentWillUnmount() {
		clearTimeout(this.timeout);
	},
	cycle() {
		this.timeout = setTimeout(()=>{
			let index = this.state.index+1 < this.props.slides.length ? this.state.index+1 : 0;
			this.setState({index}, this.cycle);
		}, 7000);
	},
	render() {
		return (
			<div className='Slider'>
				{this.props.slides.map((el,i)=>{
					return (
						<div key={i} className={'Slider_item '+(i===this.state.index ? 'is-active' : '')} style={{backgroundImage: `url("${imgSrc(legacyMedia(el.SRC))}")`}}>
							<div className='Slider_body'>
								<div className='Slider_message'>
									<div className='Slider_message_body'>
										<div className='Slider_title' dangerouslySetInnerHTML={{__html: el.TITLE}}/>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		);
	},
});
