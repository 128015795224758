import React from 'react';
import ajax from 'arcdynamic-request';
import DocTitle from 'react-document-title';
import Contents from '../Contents';
import Editable from '../Editable';

function getPage(pageCode) {
	return ajax(arc.path.api, {
		service: 'arcimedes',
		action: 'open.datasource.table.Data.getData',
		params: ['code', 'DAVIDSPAGES', {
			filter: [
				{
					text: pageCode,
					code: 'PAGECODE',
					type: 'exact',
				},
			],
			limit: {
				count: 1,
			},
		}],
	}, {cache: true}).then((res)=>{
		return {
			page: res && res.success ? res.data[0] : false,
		}
	});
}

export default React.createClass({
	statics: {
		fetch(props) {
			return Promise.all([
				getPage('events'),
				ajax(arc.path.api, {
					service: 'arcimedes',
					action: 'open.datasource.table.Data.getData',
					params: ['code', 'DAVIDSEVENTS', {
						limit: {
							count: 1000,
						},
						order: [
							{
								code: 'STARTDATE',
								type: 'asc',
							}
						],
					}],
				}, {cache: true}).then((res)=>{
					return {
						events: res && res.success ? res.data : [],
					}
				}),
			]);
		}
	},
	onSave(column, content) {
		return ajax(arc.path.api, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.updateByTableCode',
			params: ['DAVIDSPAGES', {
				value: [
					{ARCIMEDES_ROW_ID: this.props.page.ARCIMEDES_ROW_ID, [column]: content},
				],
			}],
		}, {cache: true}).then((res)=>{
			// todo
		});
	},
	render() {
		return (
			<div className='container'>
				<DocTitle title={this.props.page.TITLE}/>
				<div className='container_side'>
					<Editable html={this.props.page.ASIDE} enabled={this.props.isAdmin} onSave={(html)=> this.onSave('ASIDE',html)}/>
					<Contents ref='contents' hash={this.props.location.hash}/>					
				</div>
				<div className='container_main'>
					<Editable tag='h1' html={this.props.page.TITLE} enabled={this.props.isAdmin} whitelist={{}} onSave={(html, text)=> this.onSave('TITLE',text)}/>
					<hr/>
					<h2>Trade Show Calendar</h2>
					<table>
						<thead>
							<tr>
								<th>Event</th>
								<th>Location</th>
								<th>Date</th>
								<th>Booth Number</th>
							</tr>
						</thead>
						<tbody>
							{this.props.events.map((el,i)=>{
								let isExpired;
								if (el.ENDDATE) {
									const d = new Date(el.ENDDATE);
									if (!isNaN(d)) {
										d.setDate(d.getDate()+1); // add one day of buffer
										isExpired = (new Date()).getTime() > d.getTime();
									}
								}

								return (
									<tr key={i} className={isExpired ? 'color-mute' : ''}>
										<td>{el.NAME}</td>
										<td>{el.LOCATION}</td>
										<td>{el.STARTEXT || el.STARTDATE} - {el.ENDTEXT || el.ENDDATE}</td>
										<td>{el.BOOTH}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
});