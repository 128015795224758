import React from 'react';
import SortTh from '../SortTh/';

export default React.createClass({
	propTypes() {
		return {
			columns: React.PropTypes.array.isRequired,
			handleChange: React.PropTypes.func,
			order: React.PropTypes.string,
		};
	},
	handleClick(e) {
		let newOrder;

		e.preventDefault();

		if (this.props.order === this.props.desc) {
			newOrder = this.props.asc;
		} else if (this.props.order === this.props.asc) {
			newOrder = this.props.desc;
		} else {
			newOrder = this.props[this.props.defaultOrder];
		}

		this.props.handleChange(newOrder);
	},
	render() {
		return (
			<table className='SortTable'>
				<thead>
					<tr>
						{this.props.columns.map((el,i)=> {
							return (
								<SortTh 
									key={i}
									handleChange={this.props.setOrder} 
									value={this.props.order} 
									asc={el.asc} 
									desc={el.desc} 
									defaultOrder={el.defaultOrder}>
									{el.name}
								</SortTh>
							);
						})}
					</tr>
				</thead>
				<tbody>
					{this.props.rows}
				</tbody>
			</table>
		);
	},
});