import React from 'react';
import Control from 'react-control';

export default React.createClass({
	propTypes() {
		return {
			desc: React.PropTypes.string,
			asc: React.PropTypes.string,
			value: React.PropTypes.string,
			defaultOrder: React.PropTypes.string,
			handleChange: React.PropTypes.func,
		};
	},
	getDefaultProps() {
		return {
			value: null,
			defaultOrder: 'desc',
			title: null,
			iconAsc: 'fa fa-sort-asc',
			iconDesc: 'fa fa-sort-desc',
			iconNeutral: 'fa fa-unsorted color-mute',
		};
	},
	handleClick(e) {
		let newOrder;

		e.preventDefault();

		if (this.props.value === this.props.desc) {
			newOrder = this.props.asc;
		} else if (this.props.value === this.props.asc) {
			newOrder = this.props.desc;
		} else {
			newOrder = this.props[this.props.defaultOrder];
		}

		this.props.handleChange(newOrder);
	},
	render() {
		let body = null;
		if (this.props.asc && this.props.desc) {
			let icon;

			if (this.props.value === this.props.desc) {
				icon = this.props.iconDesc;
			} else if (this.props.value === this.props.asc) {
				icon = this.props.iconAsc;
			} else {
				icon = this.props.iconNeutral;
			}

			body = (
				<Control className='SortTh_body' onClick={this.handleClick}>
					<span className='SortTh_name'>{this.props.children}</span>
					<icon className={'SortTh_icon '+icon}/>
				</Control>
			);
		} else {
			body = (
				<div className='SortTh_body'>
					<span className='SortTh_name'>{this.props.children}</span>
				</div>
			);
		}

		return (
			<th className='SortTh' title={this.props.title}>
				{body}
			</th>
		);
	},
});