import tree from './tree';

const queue = Object.create(null);
let id = 0;

export default ()=>{
	let key = ++id;

	queue[key] = true;
	tree.select('isLoading').set(true);
	return {
		done() {
			delete queue[key];
			if (!Object.keys(queue).length) {
				tree.select('isLoading').set(false);
			}
		}
	};
};