import React from 'react';
import Link from '../Link';
import Control from 'react-control';

export default React.createClass({
	getDefaultProps() {
		return {
			dropdown: false,
		};
	},
	openMenu() {
		if (!this.props.dropdown) {
			this.props.cursor.select('dropdown').set(true);
			document.addEventListener('click', this.closeMenu);
		}
	},
	closeMenu() {
		this.props.cursor.select('dropdown').set(false);
		document.removeEventListener('click', this.closeMenu);
	},
	render() {
		const nav = [
			{
				name: 'About',
				href: arc.path.base+'about',
			},
			{
				name: 'Sustainability',
				href: arc.path.base+'sustainability',
			},
			{
				name: 'Products',
				href: arc.path.base+'products',
			},
			{
				name: 'Merchandising Tools',
				href: arc.path.base+'merchandising',
			},
			{
				name: 'Fundraising',
				href: arc.path.base+'fundraising',
			},
			{
				name: 'Events',
				href: arc.path.base+'events',
			},
			{
				name: 'Supplies',
				href: arc.path.base+'supplies',
			},
			{
				name: 'Digital Catalog',
				href: 'https://davidscookies.cld.bz/David-s-Cookies-Product-Catalog',
				bypassRouting: true,
			},
			{
				name: 'Contact',
				href: arc.path.base+'contact',
			},			
		];

		return (
			<div className='MainNav'>
				<div className='MainNav_body'>
					<Control onClick={this.openMenu} className='MainNav_hamburger' aria-label='Menu'>
						<i className='MainNav_hamburger_icon fa fa-bars'/>
					</Control>
					<div className={'MainNav_menu '+(this.props.dropdown ? 'MainNav_menu--open' : '')}>
						<Link to={arc.path.base} activeClassName='is-active' onlyActiveOnIndex={true} className='MainNav_item'>Home</Link>
						{nav.map((el,i)=> {
							if (el.bypassRouting) {
								return <a href={el.href} target='_blank' key={i} className='MainNav_item'>{el.name}</a>
							}
							return <Link to={el.href} activeClassName='is-active' key={i} className='MainNav_item'>{el.name}</Link>
						})}
					</div>
				</div>
			</div>
		);
	}
});