import React from 'react';
import Control from 'react-control';

export default React.createClass({
	render() {
		return (
			<Control className='TagButton' onClick={this.props.onClick}>
				<span className='TagButton_term'>{this.props.children}</span>
				<i className='TagButton_icon fa fa-times-circle'/>
			</Control>
		);
	},
});
