import React from 'react';
import Field from '../Field/';
import USStates from 'united-states';
import ajax from 'arcdynamic-request';
import parseForm from 'form-to-object';

export default React.createClass({
	getInitialState() {
		return {
			isCurrentCustomer: false,
			productMenu: false,
			submissionSuccess: null,
		};
	},
	handleSubmit(e) {
		e.preventDefault();

		const formData = parseForm(e.currentTarget);
	
		ajax(arc.path.api, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.updateData',
			params: ['code','contactForm'],
			options: {
				value: [
					{
						TYPE: 'contactForm',
						FIRST_NAME: formData.firstName || '',
						LAST_NAME: formData.lastName || '',
						TITLE: formData.title || '',
						EMAIL: formData.email || '',
						COMPANY_NAME: formData.companyName || '',
						ADDRESS_LN_1: formData.addr1 || '',
						ADDRESS_LN_2: formData.addr2 || '',
						CITY: formData.city || '',
						STATE: formData.state || '',
						ZIP: formData.zip || '',
						COUNTRY: formData.country || '',
						PHONE: formData.phone || '',
						FAX: formData.fax || '',
						BEST_CONTACT_TIME: formData.contactTime || '',
						MARKET_SEGMENT: formData.marketSegment || '',
						YEARS_OF_OPERATION: formData.yearsOfOperation || '',
						NUMBER_OF_LOCATIONS: formData.numberOfLocations || '',
						IS_CURRENT_CUSTOMER: formData.isCurrentCustomer || '',
						DISTRIBUTOR_NAME: formData.distributorName || '',
						DISTRIBUTOR_STATE: formData.distributorState || '',
						DISTRIBUTOR_CITY: formData.distributorCity || '',
						INQUIRY_TYPE: formData.inquery || '',
						PRODUCTS_CHECKED: Array.isArray(formData.product) ? formData.product.join(', ') : '',
						ADDITIONAL_INFO: formData.additionalInfo || '',
					}
				]
			},
		}).then(res =>{
			if (!this.isMounted()) return;

			if (res && res.success) {
				this.setState({
					submissionSuccess: true,
				});
			} else {
				this.setState({
					submissionSuccess: false,
				});
			}
		});
	},
	renderForm() {
		return (
			<form onSubmit={this.handleSubmit}>
				<h3>Contact Information</h3>
				<Field label='First Name' required>
					<input name='firstName' type='text' required/>
				</Field>
				<Field label='Last Name' required>
					<input name='lastName' type='text' required/>
				</Field>
				<Field label='Title'>
					<input name='title' type='text'/>
				</Field>
				<Field label='Email' required>
					<input name='email' type='email' required/>
				</Field>
				<Field label='Company Name' required>
					<input name='companyName' type='text' required/>
				</Field>
				<Field label='Address Line 1' required>
					<input name='addr1' type='text' required/>
				</Field>
				<Field label='Address Line 2'>
					<input name='addr2' type='text'/>
				</Field>
				<Field label='City' required>
					<input name='city' type='text' required/>
				</Field>
				<Field label='State' required>
					<select name='state' required>
						<option></option>
						{USStates.map(el => <option key={el.abbr} value={el.abbr}>{el.name}</option>)}
					</select>
				</Field>
				<Field label='Zip' required>
					<input name='zip' type='text' required/>
				</Field>
				<Field label='Country'>
					<input name='country' type='text'/>
				</Field>
				<Field label='Phone' required>
					<input name='phone' type='text' required/>
				</Field>
				<Field label='Fax'>
					<input name='fax' type='text'/>
				</Field>
				<Field label='Best time to contact you' required>
					<ul className='compact-options'>
						<li>
							<div className='form-checkbox'>
								<label><input type='radio' required name='contactTime' value='Morning'/> Morning</label>
							</div>
						</li>
						<li>
							<div className='form-checkbox'>
								<label><input type='radio' required name='contactTime' value='Afternoon'/> Afternoon</label>
							</div>
						</li>
						<li>
							<div className='form-checkbox'>
								<label><input type='radio' required name='contactTime' value='Evening'/> Evening</label>
							</div>
						</li>
					</ul>
				</Field>
				<h3>Business History</h3>
				<Field label='Market Segment' required>
					<select name='marketSegment' required>
						<option></option>
						<option value={'K-12'}>{'K-12'}</option>
						<option value={'B & I'}>{'B & I'}</option>
						<option value={'Catering'}>{'Catering'}</option>
						<option value={'College/University'}>{'College/University'}</option>
						<option value={'Convenience Store'}>{'Convenience Store'}</option>
						<option value={'Foodservice Distributor'}>{'Foodservice Distributor'}</option>
						<option value={'Foodservice Management Operator'}>{'Foodservice Management Operator'}</option>
						<option value={'Full Service Restaurant'}>{'Full Service Restaurant'}</option>
						<option value={'Hotel/Resort/Lodging'}>{'Hotel/Resort/Lodging'}</option>
						<option value={'Ice Cream Parlor'}>{'Ice Cream Parlor'}</option>
						<option value={'Independent Restaurant'}>{'Independent Restaurant'}</option>
						<option value={'Limited Service/Quick Service Restaurant'}>{'Limited Service/Quick Service Restaurant'}</option>
						<option value={'Military'}>{'Military'}</option>
						<option value={'National/Regional Restaurant Chain'}>{'National/Regional Restaurant Chain'}</option>
						<option value={'Recreation'}>{'Recreation'}</option>
						<option value={'In-Store Bakery'}>{'In-Store Bakery'}</option>
						<option value={'Travel'}>{'Travel'}</option>
						<option value={'Other'}>{'Other'}</option>
					</select>
				</Field>
				<Field label='Years of operation' required>
					<ul className='compact-options'>
						<li>
							<div className='form-checkbox'>
								<label><input type='radio' required name='yearsOfOperation' value='0-1'/> 0-1</label>
							</div>
						</li>
						<li>
							<div className='form-checkbox'>
								<label><input type='radio' required name='yearsOfOperation' value='2-4'/> 2-4</label>
							</div>
						</li>
						<li>
							<div className='form-checkbox'>
								<label><input type='radio' required name='yearsOfOperation' value='5-9'/> 5-9</label>
							</div>
						</li>
						<li>
							<div className='form-checkbox'>
								<label><input type='radio' required name='yearsOfOperation' value='10+'/> 10+</label>
							</div>
						</li>
					</ul>
				</Field>
				<Field label='Number of locations'>
					<input name='numberOfLocations' type='number'/>
				</Field>
				<Field label='Currently buying our product?' required>
					<ul className='compact-options'>
						<li>
							<div className='form-checkbox'>
								<label><input type='radio' required name='isCurrentCustomer' value='yes' onChange={(e)=> this.setState({isCurrentCustomer: e.currentTarget.value})}/> Yes</label>
							</div>
						</li>
						<li>
							<div className='form-checkbox'>
								<label><input type='radio' required name='isCurrentCustomer' value='no' onChange={(e)=> this.setState({isCurrentCustomer: e.currentTarget.value})}/> No</label>
							</div>
						</li>
					</ul>
				</Field>
				<Field label='Who is your primary distributor' required>
					<input name='distributorName' type='text' required/>
				</Field>
				<Field label='Distributor state'>
					<select name='distributorState'>
						<option></option>
						{USStates.map(el => <option key={el.abbr} value={el.abbr}>{el.name}</option>)}
					</select>
				</Field>
				<Field label='Distributor city' required>
					<input name='distributorCity' type='text' required/>
				</Field>

				<h3>Inquiry</h3>

				<Field label='Type of Inquiry' required>
					<select name='inquery' onChange={(e)=> this.setState({productMenu: (['New Business','Product Information'].indexOf(e.currentTarget.value) > -1)})} required>
						<option></option>
						<option value='Where to buy'>Where to buy</option>
						<option value='New Business'>New Business</option>
						<option value='Product Information'>Product Information</option>
						<option value='Document/Certificate Request'>Document/Certificate Request</option>
						<option value='Merchandising'>Merchandising</option>
						<option value='Code Date Reading'>Code Date Reading</option>
						<option value='Other'>Other</option>
					</select>
				</Field>
				{
					this.state.productMenu ? (
						<Field label='Which Products Are You Interested' required>
							<p className='note'>Check all that apply.</p>
							{[
								'Cookie Dough',
								'Thaw & Serve Cookies',
								'Muffin Batter',
								'Thaw & Serve Muffins',
								'Scones',
								'Cinnamon Rolls & Danishes',
								'Ruggalach',
								'Brownies & Crumbcakes',
								'Cheesecakes',
								'Tarts',
								'Layered Cakes',
								'Individual Desserts',
								'No Sugar Added',
								'Parve Products',
								'Whole Grain',
								'Gluten Free',
							].map((el,i) => {
								return (
									<div key={i} className='form-checkbox'>
										<label><input type='checkbox' name='product' value={el}/> {el}</label>
									</div>
								);
							})}
						</Field>
					) : null
				}
				<Field label='Additional Information' required>
					<textarea name='additionalInfo' required/>
				</Field>				
				<button>Submit</button>
			</form>
		);
	},
	renderSuccess() {
		return (
			<div>
				<p>Contact info submitted. Thank you!</p>
			</div>
		);
	},
	renderError() {
		return (
			<div>
				<h3>Beta testing</h3>
				<p>We are currently testing this version of David's Food Services. The form data you submitted was not recorded at this time.</p>
			</div>
		);
	},
	render() {
		return this.state.submissionSuccess == null ? this.renderForm() : (this.state.submissionSuccess ? this.renderSuccess() : this.renderError());
	}
});