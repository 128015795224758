import React from 'react';
import Image from 'arc-dynamic-img';
import Lightbox from '../Lightbox';
import imgSrc from 'arcdynamic-resize';

export default React.createClass({
	getInitialState() {
		return {
			isOpen: false,
			lightboxOpts: {
				src: this.props.src,
				alt: this.props.alt,
				group: '*',
			}
		};
	},
	handleOpen(e) {
		// ignoring when opening a new tab or window
		if (this.props.src && !e.metaKey && !e.ctrlKey && !e.shiftKey) {
			e.preventDefault();
			this.setState({isOpen: true});
		}
	},
	componentDidMount() {
		if (this.props.src) {
			Lightbox.add(this.state.lightboxOpts);
		}

	},
	componentWillUnmount() {
		if (this.props.src) {
			Lightbox.remove(this.state.lightboxOpts);
		}
	},
	render() {
		return (
			<a className={'Photo '+(this.props.className || '')} href={this.props.src} onClick={this.handleOpen}>
				<Image src={imgSrc(this.props.src, this.props.width)} alt={this.props.alt} width={this.props.width} height={this.props.height}/>
				{
					this.props.src ? <i className='Photo_zoom-icon fa fa-search'/> : null
				}
				{
					this.state.isOpen && this.props.src ? <Lightbox opts={this.state.lightboxOpts} onClose={()=> this.setState({isOpen: false})}/> : null
				}
			</a>
		);
	}
});